import { Button, Form, Input, Layout, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { EMPTY_USER } from "../../utils/user";
import useApiPost from "../../hooks/useApiPost";
import { Delegate, IOption } from "../Admin/admin-dashboard/types";

const { Content } = Layout;

const RegisterDelegate = () => {
  const headerText = "Registration of Delegate for Instore Program";

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token") || "";

  const [form] = Form.useForm();
  const { noAuthRequest } = useApiPost();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genderOptions, setGenderOptions] = useState<IOption[]>([]);
  const [ethnicityOptions, setEthnicityOptions] = useState<IOption[]>([]);
  const [languageOptions, setLanguageOptions] = useState<IOption[]>([]);
  const [delegateNetworkOptions, setdelegateNetworkOptions] = useState<
    IOption[]
  >([]);

  const [isExpired, setIsExpired] = useState({ value: false, msg: "" });

  const [formOptionValues, setFormOptionValues] = useState({
    memberRaceId: null,
    memberNetworkId: null,
    memberFirstLanguageId: null,
    memberGender: null,
  });

  const [initValues, setInitValues] = useState<Delegate>(EMPTY_USER);

  const fetchDelegateData = async () => {
    setIsLoading(true);
    if (!token) {
      return;
    }
    const delegateResponse = await noAuthRequest(
      `/office/delegates/delegate-details?token=${token}`,
      "GET"
    );
    if (delegateResponse?.status === 200) {
      fetchAllData();
      const {
        memberNetworkId,
        memberFirstLanguageId,
        memberGender,
        memberRaceId,
      } = delegateResponse?.data;

      setInitValues({ ...delegateResponse?.data });

      setFormOptionValues({
        memberRaceId,
        memberNetworkId,
        memberFirstLanguageId,
        memberGender,
      });
      setIsExpired({
        value: false,
        msg: "",
      });
    } else {
      if (delegateResponse?.status === 400) {
        setIsExpired({
          value: true,
          msg: delegateResponse?.message,
        });
      } else {
        toast.info(delegateResponse.message, {
          position: "bottom-center",
        });
      }
    }
    setIsLoading(false);
  };

  const fetchAllData = async () => {
    setIsLoading(true);
    const { networks, roles, ethnicityOptions, genderOptions, languages } =
      await noAuthRequest(
        "/office/delegates/options/creation/delegate",
        "POST",
        {
          isAuthorization: "false",
        }
      );

    setGenderOptions(genderOptions);
    setLanguageOptions(languages);
    setEthnicityOptions(ethnicityOptions);
    setdelegateNetworkOptions(networks);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDelegateData();
  }, [token]);

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, token]);

  

  const onFinish = async (values: any) => {
    setIsLoading(true);

    const { memberRoleID }: any = initValues || {};
    const submitValue = {
      firstName: values?.memberFirstName,
      lastName: values?.memberLastName,
      mobileNumber: values?.memberContactNum,
      role: values?.memberRole,
      role_id: memberRoleID,
      rc_id: values?.memberRCid,
      email: values?.email,
      gender: formOptionValues?.memberGender,
      ethnicity_id: formOptionValues?.memberRaceId,
      primaryLanguage_id: formOptionValues?.memberFirstLanguageId,
      network_id: formOptionValues?.memberNetworkId,
    };
 
    if (submitValue?.email && submitValue?.rc_id) {
      let res = await noAuthRequest(
        "/office/delegates/update-delegate-details",
        "PATCH",
        {
          ...submitValue,
        }
      );
      if (res?.status === 200) {
        goToLoginScreen();
        toast.info(`${res.message} \nyou will redirected to login page`, {
          position: "bottom-center",
        });
      } else {
        toast.info(res.message, {
          position: "bottom-center",
        });
      }
    }else{
      alert('something went wrong!!')
    }

    setIsLoading(false);
  };

  const goToLoginScreen = () => {
    navigate("/login");
  };

  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value,
    });
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isExpired.value) {
    return (
      <div>
        <Typography.Title>{isExpired.msg}</Typography.Title>
      </div>
    );
  }

  const layoutStyles = {
    display: "flex",
    margin: "auto",
    borderRadius: "16px",
    height: "100%",
  };

  return (
    <Layout style={layoutStyles}>
      <Layout className="site-layout">
        <Content style={{ padding: "16px", overflow: "auto" }}>
          <>
            <Typography.Title>{headerText}</Typography.Title>
            <Form
              form={form}
              labelCol={{ span: 4 }}
              onFinish={onFinish}
              initialValues={initValues}
            >
              <Form.Item label="Name" name="memberFirstName">
                <Input />
              </Form.Item>
              <Form.Item label="Surname" name="memberLastName">
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>

              <Form.Item label="Phone Number" name="memberContactNum">
                <Input />
              </Form.Item>
              <Form.Item
                label="Role"
                name="memberRole"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="ID Number"
                name="memberIDNumber"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item label="Gender" name="gender">
                <Select
                  loading={isLoading}
                  options={genderOptions}
                  defaultValue={formOptionValues.memberGender}
                  onChange={(_value, e) => {
                    handleOptionChange("memberGender", e);
                  }}
                />
              </Form.Item>
              <Form.Item label="Ethnicity" name="ethnicity_id">
                <Select
                  loading={isLoading}
                  options={ethnicityOptions}
                  defaultValue={formOptionValues.memberRaceId}
                  onChange={(_value, e) => {
                    handleOptionChange("memberRaceId", e);
                  }}
                />
              </Form.Item>
              <Form.Item label="Language" name="language_id">
                <Select
                  loading={isLoading}
                  options={languageOptions}
                  defaultValue={formOptionValues.memberFirstLanguageId}
                  onChange={(_value, e) => {
                    handleOptionChange("memberFirstLanguageId", e);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Retail Cloud Id"
                name="memberRCid"
                rules={[
                  {
                    required: true,
                    message: "Retail Cloud ID is mandatory",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>

              <Form.Item label="Network">
                <Select
                  loading={isLoading}
                  options={delegateNetworkOptions}
                  defaultValue={formOptionValues.memberNetworkId}
                  onChange={(_value, e) => {
                    handleOptionChange("memberNetworkId", e);
                  }}
                />
              </Form.Item>

              <Form.Item label="Outlet" name="outletName">
                <Input disabled />
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default RegisterDelegate;
